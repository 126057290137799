import React from 'react'
import Header from './header'
import Convertisseur from './Convertisseur'
import Securite from './securite'
import AvisUser from './avisUser'
import './style/header.css'


function Index() {
  return (
    <div id="index">
      <Header />
    <Convertisseur />
    
      <Securite /> 
      
      <AvisUser />
     
    </div>
  )
}

export default Index
import React from 'react'
import "./style/securite.css"
import image1 from "../../assets/logo/solde.png"
import image2 from "../../assets/logo/sms.png"


function Securite() {



  return (
    <nav className='section'>
      <div className="peach-background  " >
        <div className="w-container">
          <h1 className="section-heading">Pratique, rapide et sécurisé.</h1>
          <p className="centered">Découvrez notre application smartphone, une expérience intuitive qui simplifie l'envoi d'argent à votre famille et a vos amis en quelques cliques. Oubliez les files d'attente interminables, les frais excessifs et les soucis concernant la réception des virements par vos proches. Avec notre application iLinkWorld, envoiez de l'argent en toute simplicité, directement depuis votre téléphone, et restez connecté avec ceux qui comptent le plus pour vous.<br></br></p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6" >
              <div className="texting-graphic feature-graphic">
                <img style={{height: "360px"}} src={image1} />
              </div>
              <div className='col-md-6"'>
                <h2 className="heading">Aussi facile que d'envoyer un SMS</h2>
                <p className="feature-paragraph">Envoyer de l'argent mobile est aussi simple que d'envoyer un SMS. Tout ce dont vous avez besoin est le nom et le numéro de téléphone de votre ami.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="instant-graphic feature-graphic" >
              <img style={{height: "360px"}} src={image2} />
              </div>
              <div >
              <h2 className="heading">Transferts rapides, sans tracas</h2>
              <p className="feature-paragraph">Nous versons immédiatement les fonds de votre carte bancaire sur le compte mobile money de votre ami. </p>
              </div>
              </div>
          </div>
            {/* <a href="https://support.taptapsend.com/hc/articles/360001301227" target="_blank" className="form-button slate-background w-inline-block">
              <div className="normal-weight">En savoir plus à propos de Mobile Money</div>
            </a> */}
        </div>
      </div>
    </nav>
  )
}

export default Securite
import React, { useEffect, useState } from "react";
import "./style/navbar.css";
import logo from '../assets/works/logo.png'
// import { FaHamburger } from "react-icons/fa";
import { FiAlignLeft } from "react-icons/fi"
import { Link } from "react-router-dom";
import { motion } from "framer-motion";



export default function NavBar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  // Écouter le scroll lorsque le composant est monté
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événements lorsque le composant est démonté
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" }); // Scroll smoothly
  };

  return (
    <nav className={`navigation ${isScrolled ? 'scrolled' : ''}`}>
      <img src={logo}  className="logo"/>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        } }
      >
        <FiAlignLeft style={{ color: 'white', fontSize: "33px" }} />
      </button>

      <div
        className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}
      >
        <ul>
        <li>
            <motion.a
              href="/"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              style={{color: 'white'}}
            >
                ACCUEIL
            </motion.a>
        </li>
        <li>
            <motion.a
              href="/#services"
              onClick={() => {
                scrollToSection("services");
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              style={{color: 'white'}}
            >
              NOS SERVICES
            </motion.a>
        </li>      
        
          <li>
              <motion.a
              href="/#plateforme"
              onClick={() => {
                scrollToSection("plateforme");
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              style={{color: 'white'}}
            >
              NOTRE PLATEFORME
            </motion.a>
          </li>
         
          {/* <li>
              <motion.a
              href="/index"
             
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              style={{color: 'white'}}
            >
              TRANSFERT D'ARGENT
            </motion.a>
          </li>
         */}
          <li>
              <motion.a
              href="/#about"
              onClick={() => {
                scrollToSection("about");
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              style={{color: 'white'}}
            >
              À PROPOS DE NOUS
            </motion.a>
          </li>
          <li>
              <motion.a
              href="/#contact"
              onClick={() => {
                scrollToSection("contact");
              }}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              style={{color: 'white'}}
            >
              CONTACT
            </motion.a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

import React, {useState} from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import './style/slidePage.css'
import img1 from '../assets/slides/slider-1.jpg'
import img2 from '../assets/slides/slider-2.jpg'
import img3 from '../assets/slides/slider-3.jpg'
import phone from '../assets/works/phone.png'
import rm from '../assets/works/rm.png'


const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function SlidePage() {

  const [style, setStyle] = useState({ transform: 'translate(1, 1)' });

  const handleMouseMove = (e) => {
    const textX = e.target.offsetLeft + e.target.offsetWidth / 2;
    const textY = e.target.offsetTop + e.target.offsetHeight / 2;
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const deltaX = (mouseX - textX) / 10;
    const deltaY = (mouseY - textY) / 10;

    setStyle({ transform: `translate(${deltaX}px, ${deltaY}px)` });
  };

  const handleMouseOut = () => {
    setStyle({ transform: 'translate(2, 1)' });
  };

  return (
    <>
     <AutoplaySlider
    play={true}
    cancelOnInteraction={true} // should stop playing on user interaction
    interval={10000}
    
    >
      <div className='carousel'>
        <img className='image' src={img1} />
       <div className='.slide'>
        <div className='headerTitle'>
          <h2 className='entreprise'>L'entreprise</h2>
          <h1 className='fintech'
          style={style}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}>FinTech & Insurtech.</h1>
          <h3 className="headerText"> iLink World Corporation est une entreprise spécialisée dans la recherche et le développement de solutions mobile,
            web et IoT dans la FinTech et Insurtech Nous créons de la valeur ajoutée pour les utilisateurs et les entreprises par le biais de l'innovation et du numérique. 
            </h3>
        </div>
*            
*       </div>
      </div>
      <div className='carousel'>
        <img className='image' src={img2} />
        <div className='.slide'>
            <div className='imgText'>
              <div className='gauche'>
                <p className='lien'>Le lien vers le monde </p>
                <h1 className='textNumerique'>Numerique</h1>
              </div>
            <img className='headerImg' src={phone} />
        </div>
       
        </div>
        </div>
   
    </AutoplaySlider>
    </>
  );
}

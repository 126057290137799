import React from 'react'
import './style/avis.css'
import ben from "../../assets/logo/ben.jpg"
import mbape from "../../assets/logo/mbape.jpg"
import ngalou from "../../assets/logo/nganou.jpg"
import etoo from "../../assets/logo/etoo.jpg"

function AvisUser() {
  return (
    <section className="why_section layout_padding">
    <div className="w-container">
      <div className="section-heading">
        <h2 className='section-heading'>
        Avis de nos clients
        </h2>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="img-boxe">
            <img src={ben} alt="" />
          </div>
          <div className="detail-box">
            <h5>
              Dr Jean Kalvin
            </h5>
            <p>
            Mon expérience avec iLink-World a été bonne et pas chère ! iLinkWorld est une très bonne application, je l'utilise pour envoyer de l'argent en Côte d'Ivoire. Je l'ai même utilisée pendant mes vacances en France, au Etat Unis pour m'envoyer de l'argent à moi-même
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="img-boxe">
            <img src={mbape} alt="" />
          </div>
          <div className="detail-box">
            <h5>
              Gabriella Tonze
            </h5>
            <p>
            Envoyer de l'argent n'a jamais été aussi facile, Moins cher, les taux sont attractifs. Il est très facile pour mes proches de retirer de l'argent dans tous les points POS. C'est comme si nous étions au Sénégal 😍, au Congo, Gabon au meme moment.
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="img-boxe">
            <img style={{width: "105px", height: "105px"}} src={ngalou} alt="" />
          </div>
          <div className="detail-box">
            <h5>
              Bernard ibrahim
            </h5>
            <p>
            iLink-World est une expérience époustouflante pour moi en raison de son excellent taux de change, de son service rapide et de son processus de transfert sécurisé. L'argent a été transféré en quelques secondes et j'ai même reçu 10 $ supplémentaires la première fois que j'ai utilisé cette application. Je le partagerai avec mes amis ici au Canada !
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="img-boxe">
            <img style={{width: "105px", height: "105px"}} src={etoo} alt="" />
          </div>
          <div className="detail-box">
            <h5>
              stephane carier
            </h5>
            <p>
            Mon expérience avec iLink-World a été bonne et pas chère ! iLinkWorld est une très bonne application de transfert d'argent, je l'utilise pour envoyer de l'argent au Emirats Arabes Unis a mon fournisseur, Je l'utilise egalement lorsque je suis en vacance au Cameroun et au congo. 
            </p>
          </div>
        </div>
      </div>
     
    </div>
    <div className="btn-box">
        <a href="">
          Read More
        </a>
      </div>
  </section>
  )
}

export default AvisUser
import React from 'react'

import './style/contact.css'
import { FiMail } from 'react-icons/fi'
import { FaRocketchat, FaPhone, FaLocationArrow,  } from 'react-icons/fa'
 
function Contact() {

const email = "mailto:contact@ilink-app.com"

    return (
        <div style={{marginTop: "82px"}} id="contact">
        <div className="row">
          <div className="col-md-4">
            <h3 className="numerique">Informations</h3>
            <ul className="contact contact-info-list">
              <li style={{display: 'flex', justifyContent: 'flex-start', listStyle: "none", marginBottom: "20px" }}>
                <FaLocationArrow style={{ fontSize: "26px", width: "26px", marginRight: "10px" }}/>
                <span style={{ fontSize: "15px" }}>60 Avenue jean jaurès Batiment H  5eme ETAGE F , 93310 le Pré saint-gervais , Région Parisienne</span>
              </li>
              <li style={{display: 'flex', justifyContent: 'flex-start', listStyle: "none", marginBottom: "20px"}}>
                <a href={email}>
                  <FiMail style={{ fontSize: "16px", width: "16px", marginRight: "10px", }}/>
                  <span style={{ fontSize: "15px" }}>contact@ilink-app.com</span>
                </a>
              </li>
              <li style={{display: 'flex', justifyContent: 'flex-start', listStyle: "none", marginBottom: "20px"}}>
                <FaPhone style={{ fontSize: "16px", width: "16px", marginRight: "10px" }}/>
                <span style={{ fontSize: "15px" }}>Téléphone : 00 33 6 66 44 05 27</span>
              </li>
              <li style={{display: 'flex', justifyContent: 'flex-start', listStyle: "none", marginBottom: "20px"}}>
                <FaRocketchat style={{ fontSize: "16px", width: "16px", marginRight: "10px"}}/>
                <span style={{ fontSize: "15px" }}>Whatsapp : 00 33 6 66 44 05 27</span>
              </li>
              {/* <li style={{display: 'flex', justifyContent: 'flex-start', listStyle: "none", marginBottom: "20px"}}>
                <FaPhoneAlt style={{ fontSize: "16px", width: "16px", marginRight:" 10px" }}/>
                <span>Fax : +(44) 2454 2345 2</span>
              </li> */}
            </ul>
          </div>
          <div className="col-md-8">
            <h3 class="" style={{ fontSize: "35px", }}>Contactez-nous.</h3>
            <div className="contact-form">
            <div className="input-wrapper">
              <input id="name" type="text" name="name" value="" className="no-border" placeholder="Nom complet*" />
              </div>
              <div className="input-wrapper">
              <input id="email" type="text" name="email" value="" className="no-border" placeholder="Email*" />
              </div>
              <div className="input-wrapper">
              <textarea id="message" name="message" rows="5" cols="110" className="no-border" placeholder="Message*"></textarea>
              </div>
              <button type="submit" className="submit-button"><span>Envoyer</span></button>
            </div>
          </div>
        </div>
      </div>


    )
}

export default Contact
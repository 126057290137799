import React, { useState } from 'react';
import MainPage from './components/MainPage'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import Index from './screens/envoieArgent';
import NavBar from './components/NavBar';
import Contact from './components/contact';
import NavBarTansfert from './components/NavBarTranfert';


function App() {
// const [istrans, setIstrans] = useState(false)

  return (
   <div style={{ height: '100vh' }} >
    {/* {istrans ? 
    <NavBar />
    : 
   <NavBarTansfert /> 
    } */}
    <NavBar />
    <Routes>
      <Route path="/" Component={MainPage} />
      <Route path='/index' Component={Index} />
      </Routes>
    <Contact />
   </div>
  );
}

export default App;

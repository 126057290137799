import React, {useState, useEffect} from "react";
import "./style/convertisseur.css"; // Assurez-vous d'avoir un fichier CSS pour vos styles
import cm from "../../assets/drapeaux/cam.jpg"
import ng from "../../assets/drapeaux/nig.jpg"
import sg from "../../assets/drapeaux/sen.jpg"
import ci from "../../assets/drapeaux/ci.jpg"
import bn from "../../assets/drapeaux/bn.jpg"
import co from "../../assets/drapeaux/congo.jpg"
import gb from "../../assets/drapeaux/gab.png"
import to from "../../assets/drapeaux/togo.jpg"
import tc from "../../assets/drapeaux/tchad.jpg"
// import axios from 'axios';


function Convertisseur() {
    //const [data, setData] = useState(null)
    // useEffect(() => {
    //     //fetchData(data);
    
    //      // Effectuer l'appel API lorsque le composant est monté
    //      axios.get('http://172.20.10.2:3000/category/1')
    //      .then((response) => {
          
    //        // Mettre à jour l'état du composant avec les données de réponse
    //        setData(response.data);
    //       })
    //       .catch((error) => {
    //         console.error('Erreur lors de la requête API :', error);
    //       });
    //   }, []);
      
    const countriesRetrait = [
        { name: "Cameroun", flag: cm },
        { name: "Gagbon", flag: gb  },
    ]

    const countriesCarteBancaire = [
        { name: "benin", flag: bn },
        { name: "Nigeria", flag: ng },
        { name: "Senegal", flag: sg },
        { name: "Cote ivoir", flag: ci },
    ]

    const countriesMobilemoney = [
        { name: "Cameroun", flag: cm },
        { name: "Gagbon", flag: gb  },
        { name: "Nigeria", flag: ng },
        { name: "Senegal", flag: sg },
        { name: "Cote ivoir", flag: ci },
    ]

  const countries = [
    { name: "Cameroun", flag: cm },
    { name: "Nigeria", flag: ng },
    { name: "Senegal", flag: sg },
    { name: "Cote ivoir", flag: ci },
    { name: "benin", flag: bn },
    { name: "congo", flag: co },
    { name: "Gagbon", flag: gb  },
    { name: "Togo", flag: to },
    { name: "tchad", flag: tc },
  ];

  const [sectionActive, setSectionActive] = useState("envoie1"); 

  const handleSectionClick = (section) => {
    
    setSectionActive(section);
  };
  const activeLink = " bg-blue-100 ";
  const normalLink = "";
  return (
    <>
    <div className="convertire">
     <h1 className="simulateur"><span>Simuler un transfert d'argent</span></h1>
          <div className="container">
  {/* <div className="row-cart">
    <div className="col-md-6">
      <div className='envoie1'>
        <button onClick={() => handleSectionClick("envoie1")} className={sectionActive === "envoie1" ? activeLink : normalLink}>
          <p>Envoie de Carte Vers Wallet</p>
        </button>
      </div>
      <div className='envoie3'>
        <button onClick={() => handleSectionClick("envoie3")} className={sectionActive === "envoie3" ? activeLink : normalLink}>
          <p>Retrait de la Carte en especes</p>
        </button>
      </div>
    </div>
    <div className="col-md-6">
      <div className='envoie2'>
        <button onClick={() => handleSectionClick("envoie2")} className={sectionActive === "envoie2" ? activeLink : normalLink}>
          <p>Envoie de Carte Vers Mobile money</p>
        </button>
      </div>
      <div className='envoie4'>
        <button onClick={() => handleSectionClick("envoie4")} className={sectionActive === "envoie4" ? activeLink : normalLink}>
          <p>Envoie de carte vers Compte Bancaire</p>
        </button>
      </div>
    </div>
  </div> */}
  <div className="row">
  <div className="col-md-6">
    <div className='envoie1'>
      <button onClick={() => handleSectionClick("envoie1")} className={sectionActive === "envoie1" ? activeLink : normalLink}>
        <p>Envoie de Carte vers Wallet</p>
      </button>
    </div>
    <div className='envoie3'>
      <button onClick={() => handleSectionClick("envoie3")} className={sectionActive === "envoie3" ? activeLink : normalLink}>
        <p>Retrait de la Carte en Especes</p>
      </button>
    </div>
  </div>
  <div className="col-md-6">
    <div className='envoie2'>
      <button onClick={() => handleSectionClick("envoie2")} className={sectionActive === "envoie2" ? activeLink : normalLink}>
        <p>Envoie de Carte vers Mobile Money</p>
      </button>
    </div>
    <div className='envoie4'>
      <button onClick={() => handleSectionClick("envoie4")} className={sectionActive === "envoie4" ? activeLink : normalLink}>
        <p>Envoie de Carte vers Compte Bancaire</p>
      </button>
    </div>
  </div>
</div>

            </div>

          {sectionActive === "envoie1" && (
          <div className='envoie1'>
            <div className="app-container">
            <div className="left-section">
                <h2 style={{margin: "32px", textAlign:"center"}}>Liste des pays pour les transferts :</h2>
                <ul className="country-list">
                    {countries.map((country, index) => (
                        <li key={index}>
                            <img
                                src={country.flag}
                                alt={`Drapeau de ${country.name}`}
                                className="flag" />
                            {country.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="right-section">
                <div className="card">
                    <p style={{ textAlign: 'center', fontWeight: 500, fontSize: "17px" }}>Calcul des frais d'envoie</p>
                    <div className="dropdowns">
                        <select>
                            <option value="option1">Pays depart</option>
                            <option value="option2">Pays..</option>
                            {/* Ajoutez d'autres options au dropdown */}
                        </select>
                        <select>
                            <option value="optionA">Pays reception</option>
                            <option value="optionB">Pays..</option>
                            {/* Ajoutez d'autres options au deuxième dropdown */}
                        </select>
                    </div>
                    <div className="inputs">
                        <label>Montant pays depart</label>
                        <input type="text" placeholder="Premier input" />
                        <label>Montant pays Arrivé</label>
                         <span style={{color: "#cecece"}}> 00.00 Frs</span>
                    </div>
                    <p className="copyright-text"> Taux 1$ = 612 Frs</p>
                </div>
            </div>
        </div>
          </div>
        )}
        {sectionActive === "envoie2" && (
          <div className='envoie2'>
             <div className="app-container">
            <div className="left-section">
                <h2 style={{margin: "32px", textAlign:"center"}}>Liste des pays pour les transferts :</h2>
                <ul className="country-list">
                    {countriesMobilemoney.map((country, index) => (
                        <li key={index}>
                            <img
                                src={country.flag}
                                alt={`Drapeau de ${country.name}`}
                                className="flag" />
                            {country.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="right-section">
                <div className="card">
                    <p style={{ textAlign: 'center', fontWeight: 500, fontSize: "17px" }}>Calcul des frais d'envoie</p>
                    <div className="dropdowns">
                        <select>
                            <option value="option1">Pays depart</option>
                            <option value="option2">Pays..</option>
                            {/* Ajoutez d'autres options au dropdown */}
                        </select>
                        <select>
                            <option value="optionA">Pays reception</option>
                            <option value="optionB">Pays..</option>
                            {/* Ajoutez d'autres options au deuxième dropdown */}
                        </select>
                    </div>
                    <div className="inputs">
                        <label>Montant pays depart</label>
                        <input type="text" placeholder="Premier input" />
                        <label>Montant pays Arrivé</label>
                         <span style={{color: "#cecece"}}> 00.00 Frs</span>
                    </div>
                    <p className="copyright-text"> Taux 1$ = 612 Frs</p>
                </div>
            </div>
        </div>
          </div>
        )}
        {sectionActive === "envoie3" && (
          <div className='envoie3'>
                <div className="app-container">
            <div className="left-section">
                <h2 style={{margin: "32px", textAlign:"center"}}>Liste des pays pour les transferts :</h2>
                <ul className="country-list">
                    {countriesRetrait.map((country, index) => (
                        <li key={index}>
                            <img
                                src={country.flag}
                                alt={`Drapeau de ${country.name}`}
                                className="flag" />
                            {country.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="right-section">
                <div className="card">
                    <p style={{ textAlign: 'center', fontWeight: 500, fontSize: "17px" }}>Calcul des frais d'envoie</p>
                    <div className="dropdowns">
                        <select>
                            <option value="option1">Pays depart</option>
                            <option value="option2">Pays..</option>
                            {/* Ajoutez d'autres options au dropdown */}
                        </select>
                        <select>
                            <option value="optionA">Pays reception</option>
                            <option value="optionB">Pays..</option>
                            {/* Ajoutez d'autres options au deuxième dropdown */}
                        </select>
                    </div>
                    <div className="inputs">
                        <label>Montant pays depart</label>
                        <input type="text" placeholder="Premier input" />
                        <label>Montant pays Arrivé</label>
                         <span style={{color: "#cecece"}}> 00.00 Frs</span>
                    </div>
                    <p className="copyright-text"> Taux 1$ = 612 Frs</p>
                   {/* <div> <p>Réponse de l'API :</p>
      {data && data.map((item) => (
        <p key={item.id}>{item.name}</p>
      ))}
    </div> */}
                </div>
            </div>
        </div>
          </div>
        )}
         {sectionActive === "envoie4" && (
          <div className='envoie4'>
                <div className="app-container">
            <div className="left-section">
                <h2 style={{margin: "32px", textAlign:"center"}}>Liste des pays pour les transferts :</h2>
                <ul className="country-list">
                    {countriesCarteBancaire.map((country, index) => (
                        <li key={index}>
                            <img
                                src={country.flag}
                                alt={`Drapeau de ${country.name}`}
                                className="flag" />
                            {country.name}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="right-section">
                <div className="card">
                    <p style={{ textAlign: 'center', fontWeight: 500, fontSize: "17px" }}>Calcul des frais d'envoie</p>
                    <div className="dropdowns">
                        <select>
                            <option value="option1">Pays depart</option>
                            <option value="option2">Pays..</option>
                            {/* Ajoutez d'autres options au dropdown */}
                        </select>
                        <select>
                            <option value="optionA">Pays reception</option>
                            <option value="optionB">Pays..</option>
                            {/* Ajoutez d'autres options au deuxième dropdown */}
                        </select>
                    </div>
                    <div className="inputs">
                        <label>Montant pays depart</label>
                        <input type="text" placeholder="Premier input" />
                        <label>Montant pays Arrivé</label>
                         <span style={{color: "#cecece"}}> 00.00 Frs</span>
                    </div>
                    <p className="copyright-text"> Taux 1$ = 612 Frs</p>
                   {/* <div> <p>Réponse de l'API :</p>
      {data && data.map((item) => (
        <p key={item.id}>{item.name}</p>
      ))}
    </div> */}
                </div>
            </div>
        </div>
          </div>
        )}
      </div>
      
       
   </>
  );
}



export default Convertisseur
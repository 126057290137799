import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './style/services.css'
import img1 from '../assets/services/service-icon-1.png'
import img2 from '../assets/services/service-icon-2.png'
import img3 from '../assets/services/service-icon-3.png'
import img4 from '../assets/services/service-icon-4.png'
import mobile from '../assets/services/lolo.png'

function Services() {
     const [data, setData] = useState(null)
    useEffect(() => {
        //fetchData(data);
    
         // Effectuer l'appel API lorsque le composant est monté
         axios.get('http://localhost:3000/notifications')
         .then((response) => {
          
           // Mettre à jour l'état du composant avec les données de réponse
           setData(response.data);
           console.log(response.data)
          })
          .catch((error) => {
            console.error('Erreur lors de la requête API :', error);
          });
      }, []);
  return (
    <div className='block' id="services">
    <h1 className="services"  ><span>Nos services.</span></h1>
        <div className='containe'>
            <div className='content'>
              {/* <h3>api: </h3> */}
                 {/* <div> <p>Réponse de l'API :</p>
      {data && data.map((item) => (
        <p key={item.id}>{item.name} {item.lots[' ']}</p>
      ))}
    </div> */}
    {/* {data && data.map((item) => (
     <div key={item.id}>
       <p>ID: {item.userId}</p>
    <p>Name: {item.message}</p>
    <p>Lots: {item.date_notification}</p>
     </div>
    ))} */}
    
  
                <img src={img1} />
                <h2 className="Titre">Études</h2>
                <p className='desc'>Analyser les besoins de nos clients et les aider à définir leur cahier de charges pour la digitalisation de leur processus métier.</p>
            </div>
            <div className='content'>
                <img src={img2} />
                <h2 className="Titre">Conseil</h2>
                <p className='desc'>Accompagner nos clients dans la conception de leur processus de digitalisation métier.</p>
            </div>
            <div className='content'>
                <img src={img3} />
                <h2 className="Titre">Ingénierie</h2>
                <p className='desc'>Aider nos clients dans la mise en oeuvre de leur processus métier et l'intégration dans leur système d'information.</p>
            </div>
            <div className='content'>
                <img src={img4} />
                <h2 className="Titre">Applications</h2>
                <p className='desc'>Développer des applications mobile, Web et IoT qui innovent et créent de la valeur ajoutée pour nos clients par la digitalisation de leur processus métier.</p>
            </div>
        </div>
      
        <div className="row" style={{ display: "flex" }}>
  <div className="rowContent" >
    <h3 className="numerique">Le numérique au service de l'inclusion financière <span className="tesIlink">iLinkWorld !</span></h3>
    <p>Contrairement à l'europe, à l'amérique du nord et à une partie de l'asie, l'adressage dans la majorité des villes africaines est inexistant.</p>
    <span>D'après une étude du GSMA parue est 2016, l'évolution du mobile money et du mobile Banking en Afrique, est confrontée à la difficulté de garder 
        actif les points agents physiques qui sont créés. Ce développement anarchique, entraine la disparition de 50% de ces points. En plus, l'emplacement de ces points physiques ne sont pas connus 
        par les utilisateurs, malgré les multiples services qui y sont disponibles. Notre challenge est de rapprocher par le numérique, et la géo-localisation les utilisateurs de ces agents physiques.</span>
  </div>
  <div className="rowConten" style={{flex: 1}} >
    <img className='styleImage' src={mobile} />
  </div>
</div>

    </div>
  )
}

export default Services
import React from 'react'
import './style.css'
import SlidePage from './slidePage'
import Services from './services'
import NotrePlateforme from './NotrePlateforme'
import AproposNous from './aproposNous'
import Contact from './contact'
import NavBar from './NavBar'
import Finance from './finance'

function MainPage() {

  const images = [
    {
      src: require("../assets/works/4.png"),
      alt: "Image 1",
      index: 0,
    },
    {
      src: require("../assets/works/5.png"),
      alt: "Image 2",
      index: 1,
    },
    {
      src: require("../assets/works/2.png"),
      alt: "Image 3",
      index: 2,
    },
  ];
  
  const labels = [
    "Image 1",
    "Image 2",
    "Image 3",
  ];
  
  return (
   <div>
            <SlidePage images={images} labels={labels}/>
            <Services />
            <NotrePlateforme />
            <Finance />
            <AproposNous />

   </div>
  )
}

export default MainPage
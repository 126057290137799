import React, { useState, useEffect } from "react";
import axios from "axios";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./style/plateforme.css";

const NotrePlateforme = () => {


  const [galleryItems, setGalleryItems] = useState([
    {
        src: require("../assets/works/1.png"),
        alt: "Image 1",
        index: 0,
      },
      {
        src: require("../assets/works/2.png"),
        alt: "Image 2",
        index: 1,
      },
      {
        src: require("../assets/works/3.png"),
        alt: "Image 3",
        index: 2,
      },
    {
        src: require("../assets/works/4.png"),
        alt: "Image 1",
        index: 3,
      },
      {
        src: require("../assets/works/5.png"),
        alt: "Image 2",
        index: 4,
      },
   
      
  ]);

  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  };
  const galleryItemsArray = galleryItems.map((item, index) => (
    <img key={item.index} src={item.src} className="imagePLateforme" alt={item.alt} />
  ));

   return (
    <div id="plateforme">
         <h1 className="plateforme"  ><span>Notre plateforme.</span></h1>
         <p className="paragraphe">iLinkWorld offre plusieurs services en Afrique, en Europe et au Moyent-Orient..</p>
      <AliceCarousel
        items={galleryItemsArray}
        responsive={responsive}
        autoPlayInterval={2000}
        autoPlayDirection="ltr"
        autoPlay={true}
        fadeOutAnimation={true}
        mouseTrackingEnabled={true}
        disableAutoPlayOnAction={true}
      />
    </div>
  );
}; 

export default NotrePlateforme;

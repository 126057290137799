import React from 'react'
import mobile from '../assets/works/browser-right-1.jpg'
import logo from '../assets/works/logo-web-bleu.png'
import './style/apropos.css'
import { FiAnchor} from 'react-icons/fi'
import { FaHome, FaLaptop, FaServer, FaServicestack, FaSwimmingPool } from 'react-icons/fa'


function AproposNous() {

  const data = [
    {
      id: 1,
      icon: <FaLaptop className='icons'/>,
      name: "Notre implantation",
      desc: "iLinkWorld est la seule plate-forme qui consolide les points agents physiques de tous les réseaux en Afrique  et vers l'Afrique, pour les utilisateurs du mobile money, du transfert d'argent électronique à l'international, du  mobile banking, du Prépaiement TV, du  Prépaiement/ Paiement  eau et électricité, du Courrier Express."
    },
    {
      id: 2,
      icon: <FaHome className='icons'/>,
      name: "Notre plateforme",
      desc: "La holding de ilink World Corporation , en abrégé iWC a son siège en France  au :60 Avenue jean jaurès Batiment H  5eme ETAGE F , 93310 le Pré saint-gervais , Région ParisienneContact Whatsapp : 00 33 6 66 44 05 27 Contact Téléphone : 00 33 6 66 44 05 27 e-mail : contact@ilink-app.com."
    },
    {
      id: 3,
      icon: <FaServer className='icons'/>,
      name: "Nos services en Recherche et Développement",
      desc: "Les équipes sont répartis entre la France, le Cameroun, le Gabon et le Kenya.."
    },
    {
      id: 4,
      icon: <FaSwimmingPool className='icons'/>,
      name: "Notre gouvernance",
      desc: " Notre entreprise est gouverné par un comité exécutif et stratégique de 10 membres de nationalité et culture différentes (8 africains (Nigérians ,camerounais, gabonais) et 2 français ) . la Présidence du comité exécutif est assuré par un africain."
    },
  ]


  return (
    <div className="row" style={{ display: "flex" }} id="about">
  <div className="rowContent" >
  <img src={logo} class="" />
    <div className=''>
        {data.map((item) => (
  <div className='row'>
  <div className='blocIcon col-md-2'>
    {item.icon}
  </div>
  <div className='blocText col-md-10'>
  <h3 className="apropo" >{item.name}</h3>
  <span class="textContent">
      <p>{item.desc} </p>
  </span> 
  </div>
</div>
        )) }
    

                                

    </div>
  </div>
  <div NameName="rowConten" style={{flex: 1}} >
    <img className='styleImage' src={mobile} />
  </div>
</div>

  )
}

export default AproposNous
import React, { useRef } from 'react'
import "./style/finance.css"
import money from "../assets/logo/money.png"
import { Link } from "react-router-dom"
import ee from "../assets/images/ee.png"

function Finance() {

    const imageContainerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (imageContainerRef.current) {
      const xOffset = (e.nativeEvent.offsetX / imageContainerRef.current.clientWidth) * 20; 
      const yOffset = (e.nativeEvent.offsetY / imageContainerRef.current.clientHeight) * 20; 

      imageContainerRef.current.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
    }
  };

  const handleMouseLeave = () => {
    if (imageContainerRef.current) {
      imageContainerRef.current.style.transform = 'translate(0, 0)';
    }
  };
const envoieArgent = [
  {
    did: 1,
   sousTitre: " Wallet",
   pays: 'tous les pays',
    title: "Envoie de carte vers Wallet",
    desc: "Envoyez de l'argent de votre carte Bancaire vers un wallet iLinkWorld dans Tous les pays "
  },
  {
    id: 2,
   sousTitre: " Mobile Money",
   pays: "14 pays d'Afrique ",
    title: "Envoie de carte vers Mobile Money",
    desc: "Envoyez de l'argent de votre carte Bancaire vers un compte mobile money. "
  },
  {
    id: 3,
   sousTitre: "carte vers cash",
   pays: " 2 Pays d'Afrique",
    title: "Retrait de la Carte en especes",
    desc: "Retirez de l'argent de votre carte Bancaire en cash chez un agent point de vente agrée iLinkWorld."
  },

  {
    id: 4,
   sousTitre: "carte vers Compte Bancaire",
   pays: "12 pays d'Afrique",
    title: "Envoie de carte vers Compte Bancaire",
    desc: "Envoyez de l'argent de votre carte Bancaire vers un compte Bancaire."
  },
 
]

const style = {
  //backgroundImage: `url(${ee})`, 
  backgroundColor: '#3498db',
  backgroundSize: 'cover', 
  backgroundRepeat: 'no-repeat', 
  backgroundPosition: 'center center', 
  minHeight: '90vh', 
 
};


  return (
   <div style={style}>
     <section className="about_section layout_padding">
    <div className="content">
      <div className="heading_container heading_center">
        <h1 className='numerique'>
        Envoie et Retrait d'argent
        </h1>
        <p className='container contentText'>
          iLinkWorld donne la possibilité d'envoyer de l'argent de votre carte VISA, Master Card, American Card,...... vers plusieurs canaux qui sont: Wallet, mobile money, Cash et Compte Bancaire
        </p>
      </div>
      <div  className="row-card" >
      {envoieArgent.map((item) => (
      <Link to="/index" className="card-container">
          <div className="card" key={item.id}>
            <div className="front-content">
              <p>{item.title}</p>
            </div>
            <p style={{fontSize: '16px', color: "#00204b", fontWeight: 500}}> Dans {item.pays}</p>
            <div className="content">
              <p className="heading"> {item.sousTitre} </p>
              <p>
                {item.desc}
              </p>
            </div>
          </div>
       
            
      </Link>
       ))}
       </div>
            <Link className="btn" to="/index">
              Simuler un transfert d'argent ?
            </Link>
     
    </div>
  </section>
   </div>
  )
}

export default Finance
import React from 'react'
import './style/header.css'
import mobile from '../../assets/services/lolo.png'
import playstore from "../../assets/logo/playsotre.png"
import appStore from "../../assets/logo/appStore.png"
import homePage from "../../assets/images/dd.png"
import bg from "../../assets/images/hero-bg.png"

function Header() {

  const style = {
    backgroundImage: `url(${bg})`, 
    backgroundColor: 'rgb(176, 206, 218)',
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat', 
    backgroundPosition: 'center center', 
    minHeight: '80vh', 
   
  };
  return ( 
    <div style={style}>

      
    <section className="slider_section ">
      <div className="carousel slides" >
        <div className="carousel-inner">
  
          <div className="carousel-item active">
            <div className="container ">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="detail-box">
                    <h1>
                    Envoie et Recevoir de l'argent.
                    </h1>
                    <p>
                    Envoie plus, dépense moins.
Envoyez de l'argent depuis le Royaume-Uni, l'UE, les États-Unis, le Canada et les Émirats arabes unis vers l'Afrique, l'Asie, les Caraïbes et l'Amérique du Sud à un taux avantageux. Avec des transferts rapides vers des portefeuilles d'argent mobile sur des réseaux populaires, vous pouvez envoyer de l'argent depuis votre smartphone à tout moment.
                    </p>
                   <div className='container'>
                    <div className='row'>
                    <div className="col-md-6">
                        <img style={{width: "160px", height: '60px'}} src={playstore} />
                      </div>
                      <div className="col-md-6">
                      <img style={{width: "140px"}} src={appStore} />
                      </div>
                    </div>
                   </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-box" style={{ display: 'block' }}>
                <img src={homePage} alt="" />
              </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
    </div>
   
  )
}

export default Header